import '../../App.css';
import { useState, useEffect } from 'react';


function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [visibleJobs, setVisibleJobs] = useState(4);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch(
          'https://2coqom4tm4.execute-api.us-east-1.amazonaws.com/api/linkedin/jobs'
        );
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const showMoreJobs = () => {
    setVisibleJobs(prev => prev + 4);
  };

  const hasMoreJobs = visibleJobs < jobs.length;

  if (jobs.length === 0) {
    return null;
  }

  return (
    <div>
      <section id="jobs-section" className=" m-b-5 p-t-4 p-b-4">
        <div className="wrapper--large container">
          <div className="d-flex justify-content-center" data-scrolled-into-view="true">
            <h2 className="mb-30">Explore Career Opportunities</h2>
          </div>

          <div className="space-y-4">
            {jobs.slice(0, visibleJobs).map((job) => (
              <div className="job-card" key={job.id}>
                <h2 className="job-title">{job.title}</h2>
                <a href={job.link} target='_blank' rel="noreferrer" className="learn-more">Learn more</a>
              </div>
            ))}
          </div>

          {hasMoreJobs && (
            <div className="mt-8 text-center" data-scrolled-into-view="true">
              <button
                className="text-[#FF6934] font-medium hover:text-[#e55a2a] transition-colors duration-200"
                onClick={showMoreJobs}
                id="seeMoreJobs"
              >
                See more jobs
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Jobs;