import '../../App.css'
import { useState } from 'react'

function LihNews(props) {

  return (
    <section id="lih-news" className="m-t-5 ">
      <div className="news--wrapper ">

        <div className="news--text news--container">
          {/* <img src="/images/headline_canes-2024-d.png" alt="Cannes Lions 2024: Emotions and Advertising, The Secret Formula for Success" className="w-100 desktop" /> */}
          <img src="/images/headline_canes-2024-m.png" alt="Cannes Lions 2024: Emotions and Advertising, The Secret Formula for Success" className="w-75" />
          <a href="https://www.news.lih.com/" className="news--btn">
            <span>Read more</span>
          </a>
        </div>

        <div className="news--cover news--container">
          <img src="/images/image_canes-2024-d.png" alt='Cannes Lions 2024: Emotions and Advertising, The Secret Formula for Success' className="w-100 desktop" />
          <img src="/images/image_canes-2024-m.png" alt='Cannes Lions 2024: Emotions and Advertising, The Secret Formula for Success' className="w-100 mobile" />

        </div>

      </div>
    </section>
  );
}

export default LihNews;


